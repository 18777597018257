import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendar,
    faRectangleXmark
} from "@fortawesome/free-regular-svg-icons";

import {
    faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";

import {RangeForm} from "../DateRangePicker/DateRangePicker";
import Pagination from "../Pagination/Pagination";
import InWorkTable from "./OrdersTable";
import ErrorMessage from "../Authentication/ErrorMessage";
import AuthContext from "../AuthContext";

import {useForm, FormProvider} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";


import axios from "axios";
import Cookies from "js-cookie";

import {setData} from "../../Reducers/Orders";
import {setErrors} from "../../Reducers/Errors";

import {emptyDate, emptyOderNumber} from "../../Utils/utils"

import {handleError, reportError} from "../../Utils/Errors";

import moment from "moment";

const locale = "uk_UA"

const ArchiveTable = () => {

    const data = useSelector(state => state.Pagination.currentItems)

    return (
        <InWorkTable data={data}/>
    )
}

const ArchiveOrders = () => {

    const navigate = useNavigate()

    const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;
    const dispatch = useDispatch()
    let {isAuthenticated} = useContext(AuthContext)

    const data = useSelector(state => state.Orders.data);
    // const period = useSelector(state => state.DateRangePicker.period);

    const methods = useForm()
    const {register, handleSubmit, formState: {errors}} = methods

    const onSubmitHandler = (formValues) => {
        console.log(formValues)
        if (isAuthenticated === true) {

            const number = formValues.order_number_check === true && formValues.order_number.length > 0 ? formValues.order_number : '0'

            let start_date = '00010101'
            let end_date = '00010101'

            if (formValues.period_check === true) {
                start_date = formValues.start_date
                end_date = formValues.end_date
            }

            axios.get(
                `${baseUrl}/orders/archive/${number}/${start_date}/${end_date}/`,
                {
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    }
                }).then(function (response) {

                if (response.status === 200) {

                    const responseData = response.data;
                    switch (responseData.status) {
                        case "SUCCESS":
                            dispatch(setData(responseData.data))
                            break;
                        default:
                            dispatch(setErrors([reportError('OperationErrorTryLater', locale)]))
                    }
                }

            }).catch(function (error) {
                const errorList = handleError(error)
                dispatch(setErrors(errorList))
            })
        } else {
            navigate("/")
        }
    }

    const handleDateRangeCallback = (start, end) => {
        methods.setValue('start_date', start.format("YYYYMMDD"))
        methods.setValue('end_date', end.format("YYYYMMDD"))
    };

    // const handleDateRangeClear = () => {
    //     methods.setValue('start_date', '')
    //     methods.setValue('end_date', '')
    //     let element = document.getElementById('archive_period');
    //     element.value = ""
    // }
    //
    // const handleOrderNumberClear = () => {
    //     methods.setValue('order_number', '')
    // }

    useEffect(() => {
        // methods.setValue('order_date', moment().format("YYYYMMDD"))
        methods.setValue('start_date', moment().format("YYYYMMDD"))
        methods.setValue('end_date', moment().format("YYYYMMDD"))
    }, []);

    return (
        <div className="card card-border mb-0 h-100">
            <div className="card-header card-header-action">
                <h6>Замовлення
                    <span className="badge badge-sm badge-light ms-1">{data.length}</span>
                </h6>
                <div className="card-action-wrap">
                    {/*  Free  */}
                </div>
            </div>
            <div className="card-body">
                <div className="contact-list-view">

                    <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                        {/*Head*/}

                        <FormProvider {...methods}>
                            <form noValidate={true} onSubmit={handleSubmit(onSubmitHandler)}>

                                <div className="row">

                                    <div className="col-xxl-5 col-lg-5 mb-1">
                                        <div className="text-left d-flex justify-content-start">
                                            {/*Order number*/}
                                            <div className="form-group me-3">
                                                <label className="form-label">Номер замовлення</label>
                                                <span className="input-affix-wrapper w-180p">
                                                    <div className="input-prefix form-check ">
                                                        <input
                                                            className="form-check-input is-valid mx-0"
                                                            id="order_number_check"
                                                            name="order_number_check"
                                                            type="checkbox"
                                                            {...register("order_number_check", {required: false})}/>
                                                    </div>
                                                    <input className="form-control"
                                                           id="order_number"
                                                           name="order_number"
                                                           type="text"
                                                           placeholder="xxx-xxx-xxx"
                                                           {...register("order_number", {required: false})}/>
                                                    <ErrorMessage field={"Номер"} type={errors["order_number"]?.type}/>
                                                </span>
                                            </div>
                                            {/*Order date*/}
                                            <div className="form-group">
                                                <label className="form-label">Період</label>
                                                <span className="input-affix-wrapper w-270p">
                                                    <span className="input-prefix">
                                                        <div className="input-prefix form-check ">
                                                            <input className="form-check-input is-valid mx-0"
                                                                   id="period_check"
                                                                   name="period_check"
                                                                   type="checkbox"
                                                                   {...register("period_check", {required: false})}/>
                                                        </div>
                                                    </span>
                                                    <RangeForm id={"archive_period"} useMinDate={false}
                                                               onCallback={handleDateRangeCallback}/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xxl-7 col-lg-7 mb-1">
                                        <div className="text-end">
                                            <button type="submit" className="mt-5 btn btn-primary btn-uppercase">
                                                <span>
                                                    <span className="icon"> <FontAwesomeIcon icon={faMagnifyingGlass}
                                                                                             className="bi me-2"/> </span>
                                                    <span className="btn-text">Знайти</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </FormProvider>

                        {/*...Head*/}
                        {/*Table*/}
                        <div className="row">
                            <div className="col-sm-12 table-responsive">
                                <ArchiveTable/>
                            </div>
                        </div>
                        {/*...Table*/}
                        {/*Pagination*/}
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div
                                    className="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                                    <Pagination itemsPerPage={10} data={data}/>
                                </div>
                            </div>
                        </div>
                        {/*...Pagination*/}
                    </div>

                </div>
            </div>

        </div>
    )
}

const Archive = () => {

    let {isAuthenticated} = useContext(AuthContext)

    const navigate = useNavigate()

    useEffect(() => {
        if (isAuthenticated !== true)
            navigate("/")
    }, [isAuthenticated]);

    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">Архів замовлень</h1>
            </div>
            <div className="hk-pg-body">
                <div className="row">
                    <div className="col-md-12 mb-md-4 mb-3">
                        <ArchiveOrders/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Archive