import {useContext, useState, useCallback, useEffect} from "react";
import AuthContext from "../../AuthContext";
import {FormProvider, useForm} from "react-hook-form";

import {useDropzone} from 'react-dropzone'

import {Collapse, Image} from 'react-bootstrap';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowUpFromBracket,
    faFloppyDisk,
    faMagnifyingGlass,
    faPlus,
    faChevronDown,
    faChevronUp
} from "@fortawesome/free-solid-svg-icons";

import {
    faCircleXmark
} from "@fortawesome/free-regular-svg-icons";

import {useDispatch} from "react-redux";
import ErrorMessage from "../../Authentication/ErrorMessage";
import {SelectDateForm} from "../../DateRangePicker/DateRangePicker";
import moment from "moment/moment";
import axios from "axios";
import Cookies from "js-cookie";

import {setNotifications} from "../../../Reducers/Notifications"
import {setErrors} from "../../../Reducers/Errors";
import {handleError, reportError} from "../../../Utils/Errors";
import {formatNotifications} from "../../../Utils/Notification";

const locale = "uk_UA"
const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;

const Categories = () => {

    const [open, setOpen] = useState(true);

    return (
        <div className="card card-border overflow-hidden">
            <div className="card-header card-header-action">

                <a role="button" data-bs-toggle="collapse" data-bs-target="#categories_1" aria-expanded="true"
                   onClick={() => {
                       alert('add category')
                   }}
                   className="">
                    <div className="d-flex align-items-center">
                        <h6 className="me-3 mb-0">Категорії</h6>

                        <span className="btn btn-xs btn-icon btn-rounded btn-light" data-bs-toggle="tooltip"
                              data-bs-placement="top" title="" data-bs-original-title="Add Category">
                            <span className="icon">
                                <span className="feather-icon">
                                    <FontAwesomeIcon icon={faPlus} className=""/>
                                </span>
                            </span>
                        </span>

                    </div>
                </a>
                <Link to="#" className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover"
                      onClick={() => setOpen(!open)}>
                        <span className="icon">
                            <span className="feather-icon">
                                {open ?
                                    <FontAwesomeIcon icon={faChevronDown} className="fs--2 text-secondary"/> :
                                    <FontAwesomeIcon icon={faChevronUp} className="fs--2 text-secondary"/>
                                }
                            </span>
                        </span>
                </Link>
            </div>

            <Collapse in={open}>
                <div className="card-body">
                    {/*<div className="fade show">*/}
                    <div className="h-180p">


                        <div data-simplebar="init" className="nicescroll-bar p-0">
                            <div className="simplebar-wrapper" style={{"margin": "0px"}}>
                                <div className="simplebar-height-auto-observer-wrapper">
                                    <div className="simplebar-height-auto-observer"></div>
                                </div>
                                <div className="simplebar-mask">
                                    <div className="simplebar-offset" style={{"right": "0px", "bottom": "0px"}}>
                                        <div className="simplebar-content-wrapper"
                                             style={{"height": "100%", "overflow": "hidden scroll"}}>
                                            <div className="simplebar-content" style={{"padding": "0px"}}>

                                                <div className="form-check mb-1">
                                                    <input type="checkbox" className="form-check-input"
                                                           id="catchk1"/>
                                                    <label className="form-check-label"
                                                           htmlFor="catchk1">Design</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="simplebar-placeholder"
                                     style={{"width": "217px", "height": "196px"}}></div>
                            </div>
                            <div className="simplebar-track simplebar-horizontal" style={{"visibility": "hidden"}}>
                                <div className="simplebar-scrollbar"
                                     style={{"width": "0px", "display": "none"}}></div>
                            </div>
                            <div className="simplebar-track simplebar-vertical" style={{"visibility": "visible"}}>
                                <div className="simplebar-scrollbar"
                                     style={{
                                         "height": "165px",
                                         "transform": "translate3d(0px, 0px, 0px)",
                                         "display": "block"
                                     }}></div>
                            </div>
                        </div>


                    </div>
                    {/*</div>*/}
                </div>
            </Collapse>

        </div>
    )
}

const DateStatus = ({register, methods, errors}) => {

    useEffect(() => {
        methods.setValue('date', moment().format("YYYYMMDD"))
    }, []);

    const handleDateRangeCallback = (start) => {
        // register("post_date", {value: start.format("YYYYMMDD"), required: true})
        methods.setValue('date', start.format("YYYYMMDD"))
    };

    return (
        <div className="card card-border">
            <div className="card-body">
                <div className="edit-post-form">
                    <div className="form-group">
                        <label className="form-label">Дата публікації</label>
                        <SelectDateForm id={"post_date"} name={"post_date"} readOnly={false}
                                        onCallback={handleDateRangeCallback}/>
                        {/*<input className="form-control"*/}
                        {/*       id="post_date"*/}
                        {/*       name="post_date"*/}
                        {/*       type="text"*/}
                        {/*       defaultValue="02/12/20 8:30PM"*/}
                        {/*       {...register("post_date", {required: true})}/>*/}
                        <ErrorMessage field={"Дата публікації"} type={errors["post_date"]?.type}/>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Статус</label>
                        <select className="form-select"
                                id="status"
                                name="status"
                                defaultValue="Чернетка"
                                {...register("status", {required: true})}>
                            <option value="draft">Чернетка</option>
                            <option value="published">Опубліковано</option>
                        </select>
                        <ErrorMessage field={"Статус"} type={errors["status"]?.type}/>
                    </div>

                </div>
            </div>
        </div>
    )
}

const ContentAside = ({register, methods, errors}) => {
    return (
        <div className="content-aside">
            <button className="btn btn-primary btn-block" type="submit">
                <FontAwesomeIcon icon={faFloppyDisk} className="text-yellow me-2"/>
                Зберегти
            </button>
            <button className="btn btn-outline-secondary btn-block mb-3" type="button">
                <FontAwesomeIcon icon={faMagnifyingGlass} className="text-primary me-2"/>
                Переглянути зміни
            </button>

            {/*Date, status*/}
            <DateStatus register={register} methods={methods} errors={errors}/>
            {/*...Date, status*/}

            {/*Categories*/}
            <Categories/>
            {/*...Categories*/}
        </div>
    )
}

const PostImageUpload = ({register, methods, errors}) => {

    // const dispatch = useDispatch()

    // const files = useSelector(state => state.Dropzone.files)
    // console.log('filesssssss')

    const [update, setUpdate] = useState(false)

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0)
            methods.setValue('image', acceptedFiles[0])
        // register("image", {value: URL.createObjectURL(acceptedFiles[0]), required: true})
    }, [])

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg', '.jpeg']
        }
    })

    const removeAll = () => {
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
        setUpdate(!update)
        methods.setValue('image', null)
    }

    return (
        <div className="dropify-wrapper">
            {
                acceptedFiles.length > 0 ?

                    <div className="w-100 bg-light">
                        <div className="row">
                            <div className="col-11">
                                <Image src={URL.createObjectURL(acceptedFiles[0])} fluid
                                       className="mx-auto d-block h-280p"
                                       id="image"
                                       name="image"
                                       alt="img"/>
                            </div>
                            <div className="col-1">
                                <button className="btn-icon btn-rounded btn btn-flush-danger mt-2" type="button"
                                        onClick={removeAll}>
                                    <span className="icon">
                                        <span className="feather-icon">
                                            <FontAwesomeIcon icon={faCircleXmark} className="fs-0"/>
                                        </span>
                                    </span>
                                </button>
                            </div>
                        </div>

                    </div>
                    :
                    <div {...getRootProps()}>
                        <input {...getInputProps()}
                               id="image" name="image"
                               {...register("image", {value: null, required: false})}/>
                        <div className="text-center mt-6 mb-6">
                            <FontAwesomeIcon icon={faArrowUpFromBracket} className="fs-2 text-primary mb-2"/>
                            {
                                isDragActive ?
                                    <p>Перекиньте файли сюди...</p>
                                    :
                                    <div>
                                        <p>Перетягніть деякі файли сюди або натисніть, щоб вибрати файли</p>
                                        <em>(Приймаються лише зображення *.jpg, *.jpeg і *.png)</em>
                                    </div>
                            }
                        </div>
                    </div>
            }
        </div>
    )
}

const AddPostBody = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {slug} = useParams();
    const [data, setData] = useState({})
    console.log(data)
    const methods = useForm()
    const {register, handleSubmit, formState: {errors}} = methods

    const onSubmitHandler = useCallback((formValues) => {

        const url = slug === undefined ? `${baseUrl}/news/article/add/` : `${baseUrl}/news/article/update/${slug}/`;

        // console.log(url)
        axios.post(url,
            formValues,
            {
                timeout: 5000,
                withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-CSRFToken": Cookies.get("csrftoken")
                }
            }).then(function (response) {

            if (response.status === 200) {

                const responseData = response.data;
                switch (responseData.status) {
                    case "SUCCESS":
                        if (slug === undefined) {
                            dispatch(setNotifications([formatNotifications(`Додано новий пост: ${formValues.title}`)]))
                            navigate(`/admin-news/${formValues.permalink}`)
                        }else
                            dispatch(setNotifications([formatNotifications(`Оновлено пост: ${formValues.title}`)]))
                        break;
                    default:
                        dispatch(setErrors([reportError('OperationErrorTryLater', locale)]))
                }
            }

        }).catch(function (error) {
            const errorList = handleError(error)
            dispatch(setErrors(errorList))
        })
    }, [])

    const getArticle = useCallback(async (slug) => {

        try {
            const response = await axios.get(`${baseUrl}/news/article/${slug}/`,
                {
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    }
                })

            if (response.status === 200)
                setData(response.data.article.fields)

        } catch (error) {
            const errorList = handleError(error)
            dispatch(setErrors(errorList))
        }

    }, [])

    useEffect(() => {
        console.log('useEffect')
        if (slug !== undefined){
            getArticle(slug)
        }
    }, [getArticle]);

    return (
        <div className="container-fluid">

            <FormProvider {...methods}>
                <form noValidate={true} className="edit-post-form" onSubmit={handleSubmit(onSubmitHandler)}>

                    <div className="row">
                        <div className="col-xxl-9 col-lg-8">

                            <div className="form-group">
                                <label className="form-label">Заголовок публікації</label>
                                <input className="form-control"
                                       id="title"
                                       name="title"
                                       type="text"
                                       placeholder="Заголовок публікації"
                                       defaultValue={data.title}
                                       {...register("title", {required: true})}/>
                                <ErrorMessage field={"Заголовок публікації"} type={errors["title"]?.type}/>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Постійне посилання</label>
                                <input className="form-control"
                                       id="permalink"
                                       name="permalink"
                                       type="text"
                                       placeholder="Постійне посилання"
                                       defaultValue={data.permalink}
                                       {...register("permalink", {required: true})}/>
                                <ErrorMessage field={"Постійне посилання"} type={errors["permalink"]?.type}/>
                            </div>
                            <div className="card card-border advance-option-post">
                                <div className="card-body">
                                    <h5 className="card-title">Текст публікації</h5>
                                    <textarea className="form-control" rows="8"
                                              id="text"
                                              name="text"
                                              placeholder="Текст публікації"
                                              defaultValue={data.text}
                                              {...register("text", {required: true})}></textarea>
                                    <ErrorMessage field={"Текст публікації"} type={errors["text"]?.type}/>
                                </div>
                            </div>
                            <div className="card card-border advance-option-post">
                                <div className="card-body">
                                    <h5 className="card-title">Зображення публікації</h5>
                                    <PostImageUpload register={register} methods={methods} errors={errors}/>
                                </div>
                            </div>

                        </div>
                        <div className="col-xxl-3 col-lg-4">
                            <ContentAside register={register} methods={methods} errors={errors}/>
                        </div>
                    </div>

                </form>
            </FormProvider>
        </div>
    )
}

const AddPost = () => {

    const navigate = useNavigate()

    let {isAuthenticated, isAdmin} = useContext(AuthContext)

    useEffect(() => {
        if (isAuthenticated !== true || isAdmin !== true)
            navigate('/')
    }, [isAuthenticated, isAdmin]);
    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">Додати нову публікацію</h1>
            </div>
            <div className="hk-pg-body">
                <div className="blogapp-wrap blogapp-sidebar-toggle">
                    <div className="blogapp-detail-wrap">
                        <AddPostBody/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPost