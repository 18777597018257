import {configureStore} from '@reduxjs/toolkit'

import Auth2 from "./Reducers/Auth2";
import Orders from "./Reducers/Orders";
import Errors from "./Reducers/Errors";
import User from "./Reducers/UserProfile";
import Dashboard from "./Reducers/Dashboard";
import DateRangePicker from "./Reducers/DateRangePicker";
import Pagination from "./Reducers/Pagination";
import Users from "./Reducers/Users";
import FilteredData from "./Reducers/FilteredData";
import Blog from "./Reducers/Blog";
import Dropzone from "./Reducers/Dropzone";
import Notification from "./Reducers/Notifications";

export const store = configureStore({
    reducer: {
        Auth2: Auth2,
        Orders: Orders,
        Errors: Errors,
        User: User,
        Dashboard: Dashboard,
        Pagination: Pagination,
        DateRangePicker: DateRangePicker,
        Users: Users,
        FilteredData: FilteredData,
        Blog: Blog,
        Dropzone: Dropzone,
        Notification: Notification
    }
})