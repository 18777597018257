import ReactPaginate from "react-paginate";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setCurrentItems} from "../../Reducers/Pagination";

const Pagination = ({itemsPerPage, data}) => {

    // console.log(`Pagination`)

    const dispatch = useDispatch()

    // const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const pageCount = Math.ceil(data.length / itemsPerPage);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        if (Array.isArray(data) && data.length !== 0) {
            dispatch(setCurrentItems(data.slice(itemOffset, endOffset)));
            // setPageCount(Math.ceil(data.length / itemsPerPage));
        } else {
            dispatch(setCurrentItems([]))
            // setPageCount(0)
        }

        if (data.length <= itemsPerPage && itemOffset !== 0) {
            setItemOffset(0);
        }

    }, [itemOffset, data]);

    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % data.length;
        setItemOffset(newOffset);
    };

    return (
        <ReactPaginate
            className={"pagination custom-pagination pagination-simple pagination-sm"}
            nextLabel=" >>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<< "
            pageClassName="me-1 ms-1 paginate_button page-item"
            pageLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
            previousClassName="page-item me-1"
            previousLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
            nextClassName="page-item ms-1"
            nextLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
            containerClassName="pagination mb-0"
            activeClassName="active"
            renderOnZeroPageCount={null}
        />
    )
}

export default Pagination