import SVGLogo from "../SVGLogo/SVGLogo_new";
import DropdownProfile from "./DropdownProfile";

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-svg-core/styles.css'
import {
    faFileLines
} from "@fortawesome/free-regular-svg-icons";
import {
    faXmark,
    faHeadset,
    faRadio,
    faBarsStaggered,
    faChartLine,
    faScrewdriverWrench
} from "@fortawesome/free-solid-svg-icons";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../AuthContext";

import moment from "moment";


const NavBar = ({children, className}) => {
    return (
        <ul className={className}>
            {children}
        </ul>
    )
}

const Dropdown = ({children, tittle, bsTarget, icon}) => {

    const [ariaExpanded, setAriaExpanded] = useState(false)

    const handleDropdownClick = () => {

        let element = document.getElementById(bsTarget)

        if (element) {
            if (!ariaExpanded === true) {
                element.classList.add('show');
            } else {
                element.classList.remove('show');
            }
        }
        setAriaExpanded(!ariaExpanded)
    }

    return (
        <li className="nav-item">
            <a className="nav-link" href={void (0)} data-bs-toggle="collapse" data-bs-target={`#${bsTarget}`}
               aria-expanded={ariaExpanded}
               onClick={handleDropdownClick}>
                <span className="feather-icon">

                    {icon}
                </span>
                <span className="nav-link-text">{tittle}</span>
            </a>
            <ul id={bsTarget} className="nav flex-column collapse nav-children">
                <li className="nav-item">
                    <ul className="nav flex-column">
                        {children}
                    </ul>
                </li>
            </ul>
        </li>
    )
}

const NavLink = ({badgeTittle, href, tittle, icon, active, onClick}) => {

    const haveBadge = !!badgeTittle;
    const haveIcon = !!icon;

    let activeClassName = "";

    if (active) {
        activeClassName = "active"
        // activeClassName = "text-primary-vst"
    }

    return (
        <li className={`nav-item ${activeClassName}`}>
            <a className="nav-link" href={href} onClick={onClick}>
                {haveIcon ?
                    <span className="feather-icon">
                        {icon}
                    </span> : <></>
                }
                <span className="nav-link-text">{tittle}</span>
                {haveBadge ?
                    <span className="badge badge-sm badge-soft-pink ms-xl-2 ms-auto">{badgeTittle}</span> :
                    <></>
                }
            </a>
        </li>
    )
}

const MainNavBar = () => {

    let {isAdmin} = useContext(AuthContext)

    const handleCallMenu = (e) => {
        let container = document.querySelector("[data-layout]");
        if (container) {
            container.setAttribute("data-layout-style", e.currentTarget.getAttribute("layout-style"));
        }
    }

    const [userData, setUserData] = useState({
        username: '',
        usershort: '',
        email: '',
        company: ''
    })

    useEffect(() => {
            let storageUserData = JSON.parse(localStorage.getItem("user-data"));

            if (storageUserData)
                setUserData(storageUserData);

        }, []
    )

    return (
        <div className="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
            <div className="container-fluid">
                {/*<div className="w-100">*/}
                {/*Start Nav*/}
                <div className="nav-start-wrap flex-fill">

                    {/*Brand*/}
                    <a className="navbar-brand d-xl-flex d-none flex-shrink-0" href="/">
                        <span className="svg-rated" style={{height: "50px", width: "160px"}}><SVGLogo/></span>
                    </a>
                    {/*Brand*/}

                    {/*<Navbar.Toggle className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle d-xl-none"*/}
                    {/*               aria-controls={'navbar-expand-xl'}>*/}
                    {/*    <span className="icon">*/}
                    {/*        <span className="feather-icon">*/}
                    {/*            <FontAwesomeIcon icon={faBarsStaggered} className="feather feather-align-left"/>*/}
                    {/*        </span>*/}
                    {/*    </span>*/}
                    {/*</Navbar.Toggle>*/}

                    <button
                        onClick={handleCallMenu} layout-style={"collapsed"}
                        className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle d-xl-none">
                        <span className="icon">
                            <span className="feather-icon">
                                <FontAwesomeIcon icon={faBarsStaggered} className="feather feather-align-left"/>
                            </span>
                        </span>
                    </button>

                    {/*<Navbar.Offcanvas className="hk-menu"*/}
                    {/*    id={'navbar-expand-xl'}*/}
                    {/*    aria-labelledby={'navbarLabel-expand-xl'}*/}
                    {/*    placement="start">*/}

                    <div className="hk-menu">

                        <div className="menu-header d-xl-none">
							<span>
								<span className="svg-rated" style={{height: "50px", width: "160px"}}><SVGLogo/></span>
								<button
                                    onClick={handleCallMenu} layout-style={"default"}
                                    className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle">
									<span className="icon">
										<span className="svg-icon fs-5">
                                            <FontAwesomeIcon icon={faXmark} className="icon icon-tabler"/>
										</span>
									</span>
								</button>
							</span>
                        </div>

                        {/*Main menu*/}

                        <SimpleBar className="nicescroll-bar">
                            <div className="menu-content-wrap">
                                <div className="menu-group">
                                    <NavBar className="navbar-nav flex-column">
                                        <NavLink tittle={"Головна"} href={"/"} active={false}/>

                                        <Dropdown tittle={"Замовлення у виробництві"} bsTarget={"dash_orders"}
                                                  icon={<FontAwesomeIcon icon={faFileLines}
                                                                         className="feather me-2"/>}>

                                            <NavLink tittle={"За статусом"} href={"/orders/in-work"}/>
                                            <NavLink tittle={"За датою відвантаження"} href={"/orders/shipments"}
                                                     onClick={() => {
                                                         localStorage.setItem('DRPStartDate', moment().format("YYYYMMDD"))
                                                         localStorage.setItem('DRPEndDate', moment().add(10, 'days').format("YYYYMMDD"))
                                                     }}
                                            />
                                            <NavLink tittle={"Архів"} href={"/orders/archive"}/>

                                        </Dropdown>

                                        <Dropdown tittle={"Звіти"} bsTarget={"dash_reports"}
                                                  icon={<FontAwesomeIcon icon={faChartLine}
                                                                         className="feather me-2"/>}>

                                            <NavLink tittle={"Аналіз заборгованості"}
                                                     href={"/reports/debt-analysis"}/>

                                        </Dropdown>

                                        <NavLink tittle={"Новини"} href={"/news"} active={false}
                                                 icon=<FontAwesomeIcon icon={faRadio}
                                                 className="feather me-2"/>/>

                                        <NavLink tittle={"Підтримка"} href={"/"} active={false}
                                                 icon=<FontAwesomeIcon icon={faHeadset}
                                                 className="feather me-2"/>/>

                                        {isAdmin ?
                                            <Dropdown tittle={"Адміністратор"} bsTarget={"dash_admin"}
                                                      icon={<FontAwesomeIcon icon={faScrewdriverWrench}
                                                                             className="feather me-2"/>}>

                                                <NavLink tittle={"Новини"} href={"/admin-news"}/>
                                                <NavLink tittle={"Користувачі"} href={"/admin-users"}/>

                                            </Dropdown>
                                            : <></>
                                        }

                                    </NavBar>
                                </div>
                            </div>
                        </SimpleBar>

                    </div>

                    {/*</Navbar.Offcanvas>*/}
                    {/*NavBar*/}
                    <div id="hk_menu_backdrop" className="hk-menu-backdrop"></div>
                </div>
                {/*End Nav*/}

                <div className="nav-end-wrap">
                    {/*<div className="fs-6 text-primary-vst text-end me-3">{userData.company}</div>*/}
                    <div className="mx-1 mt-2">
                        <h6>{userData.company}</h6>
                    </div>
                    <ul className="navbar-nav flex-row">
                        {/*<li className="nav-item">*/}
                        {/*    <DropdownNotifications/>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <DropdownProfile userData={userData}/>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default MainNavBar