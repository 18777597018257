import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-svg-core/styles.css'
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";


const Footer = () => {
    return (
        <div className="hk-footer">
            <footer className="container-xxl footer">
                <div className="row">
                    <div className="col-xl-8">
                        <p className="footer-text"><span className="copy-text">FixSoft © 2024 Всі права захищені.</span>
                            <a href="#" className="" target="_blank"> Політика конфіденційності</a>
                            <span className="footer-link-sep">|</span>
                            <a href="#" className="" target="_blank">T&amp;C</a>
                            <span className="footer-link-sep">|</span>
                            <a href="#" className="" target="_blank">System Status</a>
                        </p>
                    </div>
                    <div className="col-xl-4">
                        <a href="#" className="footer-extr-link link-default">
                                    <span className="feather-icon">
                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="fs-0"/>
                                    </span>
                            <u>Надішліть відгук на наш довідковий форум</u>
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer