import {useForm, FormProvider} from "react-hook-form";

import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../AuthContext";
import {useNavigate} from "react-router-dom";

import {getCSRF, signup} from "../../../Reducers/Auth2";

import AuthBody from "../AuthBody";
import BrandNav from "../BrandNav";
import ErrorMessage from "../ErrorMessage";
import Footer from "../../Footer/Footer";
import PassInput from "../PassInput";

const SignUpContent = () => {

    const dispatch = useDispatch();

    const methods = useForm()
    const {register, setError, handleSubmit, formState: {errors}} = methods

    const onSubmitHandler = (formValues, event) => {
        dispatch(signup(formValues));
    }

    return (
        <div className="auth-content py-md-0 py-8">
            <FormProvider {...methods}>
                <form noValidate={true} className="w-100" onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h4 className="mb-4 text-center">Зареєструватися в кабінет електронних послуг</h4>
                            <div className="row gx-3">
                                <div className="form-group col-lg-12">
                                    <div className="form-label-group">
                                        <label htmlFor="username">Логін (адреса електронної пошти)</label>
                                    </div>
                                    <input className={"form-control"}
                                           placeholder="user@example.com"
                                           autoComplete={"on"}
                                           id="username"
                                           {...register("username", {required:true})}
                                           type="email"/>
                                    <ErrorMessage field={"Логін"} type={errors["username"]?.type}/>
                                </div>
                                <div className="row gx-3 pe-0">
                                    <div className="form-group col-lg-6 pe-0">
                                        <div className="form-label-group">
                                            <label htmlFor="first_name">Ім’я</label>
                                        </div>
                                        <input className={"form-control"}
                                               autoComplete={"on"}
                                               placeholder="Введіть ім’я"
                                               id="first_name"
                                               {...register("first_name",{required:true})}
                                               type="text"/>
                                        <ErrorMessage field={"Ім’я"} type={errors["first_name"]?.type}/>
                                    </div>
                                    <div className="form-group col-lg-6 pe-0">
                                        <div className="form-label-group">
                                            <label htmlFor="last_name">Прізвище</label>
                                        </div>
                                        <input className={"form-control"}
                                               placeholder="Введіть прізвище"
                                               id="last_name"
                                               {...register("last_name",{required:true})}
                                               type="text"/>
                                        <ErrorMessage field={"Прізвище"} type={errors["last_name"]?.type}/>
                                    </div>
                                </div>
                                {/*password*/}
                                <PassInput label="Пароль" name="password1"/>
                                {/*password2*/}
                                <PassInput label="Пароль (підтвердження)" name="password2"/>
                            </div>
                            <button type="submit" className="btn btn-primary btn-uppercase btn-block" name="submit">
                                Зареєструвати користувача
                            </button>
                            <p className="p-xs mt-2 text-center">
                                <a className="text-dark-60" href="/login">Вже є обліковий запис? &nbsp;&nbsp;
                                    <u className="text-primary">Вхід</u>
                                </a>
                            </p>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}

const Signup = () => {

    const dispatch = useDispatch();

    let {isAuthenticated} = useContext(AuthContext)
    const navigate = useNavigate();

    useEffect(() => {
        // redirect to home if already logged in
        if (isAuthenticated === true)
            navigate('/');
        else if (typeof isAuthenticated === "boolean")
            dispatch(getCSRF());

    }, [isAuthenticated]);

    return (
        <div className={"hk-wrapper hk-pg-auth"} data-footer={"simple"}>
            <nav className="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
                <BrandNav/>
            </nav>

            <div className={"hk-pg-wrapper"}>
                <AuthBody>
                    <SignUpContent/>
                </AuthBody>
                <Footer/>
            </div>

        </div>
    )
}

export default Signup