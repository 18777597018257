import {Navigate} from 'react-router-dom';

import AuthContext from "./AuthContext";
import {useContext} from "react";

export {PrivateRoute, AdminRoute};

function PrivateRoute({children}) {

    let {isAuthenticated} = useContext(AuthContext)

    if (isAuthenticated === false) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login"/>
    }

    if (isAuthenticated === true) {
        return children
    }
}

function AdminRoute({children}) {

    let {isAuthenticated, isAdmin} = useContext(AuthContext)

    if (isAuthenticated === false) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login"/>
    }

    // if (isAdmin === false) {
    //     // not logged in so redirect to login page with the return url
    //     return <Navigate to="/"/>
    // }

    if (isAuthenticated === true && isAdmin === true) {
        return children
    }

}