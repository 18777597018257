import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import {handleError, reportError} from "../Utils/Errors";
import {updateArrayOfObjects} from "../Utils/arrayProcessing";

const locale = "uk_UA"

const initialState = {
    data: []
}

const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;

export const sendAddressUpdateRequest = createAsyncThunk('Orders/sendAddressUpdateRequest',
    async ({uid, address}) => {

        try {
            const response = await axios.post(`${baseUrl}/orders/address-update-request/${uid}/`,
                {address: address},
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    },
                    withCredentials: true,
                });

            return {data: response.data, status: response.status};
        } catch (error) {
            const errorsList = handleError(error)
            return {errors: errorsList, status: error.response.status}
        }
    }
)

export const sendPrintOrderRequest = createAsyncThunk('Orders/sendPrintOrderRequest',
    async ({uid}) => {

        try {
            const response = await axios.get(`${baseUrl}/orders/order-print-request/${uid}/`,
                // {address: address},
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    },
                    withCredentials: true,
                });

            return {data: response.data, status: response.status};
        } catch (error) {
            const errorsList = handleError(error)
            return {errors: errorsList, status: error.response.status}
        }
    }
)

export const Orders = createSlice({
    name: 'Orders',
    initialState,
    reducers: {
        setData: (state, action) => {
            return {...state, data: action.payload}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendAddressUpdateRequest.fulfilled, (state, action) => {
                if (action.payload.status === 200) {
                    return {...state, data: action.payload.data}
                } else {
                    return {...state, errors: action.payload.errors}
                }
            })
            .addCase(sendAddressUpdateRequest.rejected, (state, action) => {
                return {...state, errors: reportError("OperationErrorTryLater", locale)}
            })
            //send Print Order Request
            .addCase(sendPrintOrderRequest.fulfilled, (state, action) => {
                    if (action.payload.status === 200) {
                        // console.log(action.payload.data)
                        return {...state, data: action.payload.data.data}
                    } else {
                        return {...state, errors: action.payload.errors}
                    }
                })
            .addCase(sendPrintOrderRequest.rejected, (state, action) => {
                return {...state, errors: reportError("OperationErrorTryLater", locale)}
            })
    }
})

export const {setData} = Orders.actions
export default Orders.reducer