import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-svg-core/styles.css'
import {
    faPencil,
} from "@fortawesome/free-solid-svg-icons";
import {
    faCalendar
} from "@fortawesome/free-regular-svg-icons";

import {useContext, useEffect} from "react";
import AuthContext from "../AuthContext";
import axios from "axios";
import Cookies from "js-cookie";

import {Range} from "../DateRangePicker/DateRangePicker"

import {setData as setOrders} from "../../Reducers/Orders";
import {setErrors} from "../../Reducers/Errors";

import {useDispatch, useSelector} from "react-redux";
import Pagination from "../Pagination/Pagination";


const ShipmentsTableRow = ({rowData}) => {

    return (
        <tr className="odd">
            <td className="sorting_1">
                <div className="media align-items-center">
                    <div className="media-body">
                        <div className="text-high-em">
                            <a href={`/orders/order-details/${rowData.uid}`}
                               className="table-link-text link-high-em">{rowData.number}</a>
                        </div>
                    </div>
                </div>
                <div className="fs-7">{rowData.date}</div>
            </td>
            <td>{rowData.delivery_address}
            </td>
            <td>
                <div>{rowData.driver}</div>
                <div>{rowData.driver_telephone}</div>
            </td>
            <td>
                <span className="badge badge-soft-secondary my-1 me-2">{rowData.delivery_date}</span>
                <span className="badge badge-soft-secondary my-1 me-2">
                    {rowData.delivery_time_from}&nbsp;-&nbsp;{rowData.delivery_time_to}
                </span>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <a className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover"
                       data-bs-toggle="tooltip" data-placement="top" title=""
                       data-bs-original-title="Змінити дату доставки" href="#" aria-label="Змінити дату доставки">
                            <span className="icon">
                                <span className="feather-icon">
                                    <FontAwesomeIcon icon={faPencil} className="feather me-2"/>
                               </span>
                            </span>
                    </a>
                </div>
            </td>
        </tr>
    )
}

const ShipmentsTable = () => {

    const data = useSelector(state => state.Pagination.currentItems)

    return (
        <table id="datable_1" className="table nowrap w-100 mb-5 dataTable no-footer">
            <thead>
            <tr role="row">
                <th className="sorting_disabled mnw-150p" tabIndex="0" rowSpan="1" colSpan="1"
                    style={{width: "151.438px"}}>
                    Замовлення #
                </th>
                <th className="sorting_disabled w-25 mnw-150p" tabIndex="0" rowSpan="1" colSpan="1"
                    style={{width: "263.5px"}}>
                    Адреса доставки
                </th>
                <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1"
                    style={{width: "228.062px"}}>
                    Водій / Тел.
                </th>
                <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1"
                    style={{width: "216.805px"}}>
                    Дата / Час
                </th>
                <th className="sorting_disabled" rowSpan="1" colSpan="1" aria-label="" style={{width: "66.6797px"}}>
                    ...
                </th>
            </tr>
            </thead>
            <tbody>
            {
                data.map(el => <ShipmentsTableRow key={el.row_id} rowData={el}/>)
            }
            </tbody>
        </table>
    )
}

const ShipmentsOrders = () => {

    const orData = useSelector(state => state.Orders.data);

    return (
        <div className="card card-border mb-0 h-100">
            <div className="card-header card-header-action">
                <h6>Замовлення
                    <span className="badge badge-sm badge-light ms-1">{orData.length}</span>
                </h6>
                <div className="card-action-wrap">
                    {/*  Free  */}
                </div>
            </div>
            <div className="card-body">
                <div className="contact-list-view">

                    <div id="datable_1_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        {/*Head*/}
                        <div className="row">
                            <div className="col-10 mb-3">
                                <div className="contact-toolbar-left">
                                    <div className="form-group">
                                        <label className="form-label mx-3">Період відвантаження</label>
                                        <div className="input-group w-250p mx-3">
                                            <span className="input-affix-wrapper">
                                                <span className="input-prefix">
                                                    <span className="feather-icon">
                                                        <FontAwesomeIcon icon={faCalendar} className="feather me-2"/>
                                                    </span>
                                                </span>
                                                <Range/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 mb-3">
                                <div className="contact-toolbar-right">
                                    <div id="datable_1_filter" className="dataTables_filter">
                                        <label>
                                            <input type="search" className="form-control form-control-sm"
                                                   placeholder="Пошук" aria-controls="datable_1"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Head*/}
                        {/*Table*/}
                        <div className="row">
                            <div className="col-sm-12 table-responsive">
                                <ShipmentsTable/>
                            </div>
                        </div>
                        {/*...Table*/}
                        {/*Pagination*/}
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                                    <Pagination itemsPerPage={10} data={orData}/>
                                </div>
                            </div>
                        </div>
                        {/*...Pagination*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

const Shipments = () => {

    const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;
    const dispatch = useDispatch()

    let {isAuthenticated} = useContext(AuthContext)

    const period = useSelector(state => state.DateRangePicker.period);

    useEffect(() => {
        if (isAuthenticated === true) {
            axios.get(`${baseUrl}/orders/shipments-list/${period.startDate}/${period.endDate}/`,
                {
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    }
                }).then(function (response) {

                if (response.status === 200) {

                    const responseData = response.data;

                    switch (responseData.status) {
                        case "SUCCESS":
                            dispatch(setOrders(responseData.data))
                            break;
                        default:
                            dispatch(setErrors(['undefined error']))
                    }
                }
            }).catch(function (error) {

                const responseData = error.response.data;

                switch (error.response.status) {
                    case 400: {
                        dispatch(setErrors(responseData.errors))
                        break;
                    }
                    default: {
                        dispatch(setErrors(['undefined error']))
                    }
                }
            })
        }

    }, [period]);

    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">За датою відвантаження</h1>
            </div>
            <div className="hk-pg-body">
                <div className="row">
                    <div className="col-md-12 mb-md-4 mb-3">
                        <ShipmentsOrders/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Shipments