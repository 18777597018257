import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    is_filtered: false,
    data: [],
}

export const FilteredData = createSlice({
    name: 'FilteredData',
    initialState: initialState,
    reducers: {
        setFilteredData: (state, action) => {
            const is_filtered = action.payload.searchValue.length >= 3
            return {...state, data: action.payload.data, is_filtered: is_filtered}
        },
        setEmptyData: (state, action) => {
            return {...state, data: []}
        }
    }
})

export const {
    setFilteredData
} = FilteredData.actions
export default FilteredData.reducer