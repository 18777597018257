import {Button, Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {useCallback, useContext, useState} from "react";
import AuthContext from "../../AuthContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShareFromSquare} from "@fortawesome/free-regular-svg-icons";
import {faBan} from "@fortawesome/free-solid-svg-icons";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {SelectDateForm} from "../../DateRangePicker/DateRangePicker";
import ErrorMessage from "../../Authentication/ErrorMessage";

const DateWindow = ({show, showHandler, uid}) => {

    const [startDate, setStartDate] = useState(new Date());

    const navigate = useNavigate()

    const methods = useForm({defaultValues: {
            uid: uid,
            delivery_date: ""
        }})
    const {clearErrors,register, handleSubmit, formState: {errors}} = methods

    // let {isAuthenticated, isAdmin} = useContext(AuthContext)

    const handleDateRangeCallback = (start) => {
        methods.setValue('delivery_date', start.format("YYYYMMDD"))
    };

    const onSubmitHandler = useCallback((formValues) => {
        // console.log(formValues)
        // dispatch(updateUserUid(formValues))
        methods.setValue('delivery_date', "")
        clearErrors('delivery_date')
        showHandler(false);
    },[])

    return (
        <Modal size="md" centered show={show} onHide={() => showHandler(false)}>
            <FormProvider {...methods}>
                <form noValidate={true} onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="modal-content border-0">
                        <Modal.Header closeButton className="mx-2">
                            <Modal.Title>Вкажіть дату відвантаження</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label className="form-label">Дата відвантаження</label>
                            <SelectDateForm id={"delivery_date"} name={"delivery_date"} readOnly={false}
                                            onCallback={handleDateRangeCallback}/>
                        <ErrorMessage field={"Дата відвантаження"} type={errors["delivery_date"]?.type}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn py-2 btn-sm" type="submit">
                                <FontAwesomeIcon icon={faShareFromSquare}
                                                 className="fs-0 me-2 text-yellow"/>
                                Відправити
                            </Button>
                            <Button className="btn bg-warning py-2 btn-sm border-yellow"
                                    onClick={() => {
                                        showHandler(false)
                                    }}>
                                <FontAwesomeIcon icon={faBan} className="fs-0 me-2 text-primary"/>
                                Скасувати
                            </Button>
                        </Modal.Footer>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default DateWindow