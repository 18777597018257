const initialOrder = {
    doc: {
        uid: "",
        number: "",
        amount: 0,
        date: "",
        delivery_date: "",
        execution_date: "",
        delivery_address: "",
        additional_address: "",
        manager: ""
    },
    images: [],
    goods: [],
    services: []
}

export default initialOrder