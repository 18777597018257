import {useForm, FormProvider} from "react-hook-form";

import {useContext, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {getCSRF, login} from "../../../Reducers/Auth2"
import AuthContext from "../../AuthContext";

import '@fortawesome/fontawesome-svg-core/styles.css'

import Footer from "../../Footer/Footer";
import BrandNav from "../BrandNav";
import AuthBody from "../AuthBody";
import PassInput from "../PassInput";
import ErrorMessage from "../ErrorMessage";

const LoginContent = () => {

    // const authError = useSelector(state => state.Auth2.error);

    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const methods = useForm()
    const {register, handleSubmit, formState: {errors}} = methods

    const onSubmitHandler = (formValues) => {
        dispatch(login(formValues));
    }

    return (
        <div className="auth-content py-md-0 py-8">
            <FormProvider {...methods}>
                <form noValidate={true} className="w-100" onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h4 className="mb-4">Увійдіть у свій обліковий запис</h4>
                            <div className="row gx-3">
                                <div className="form-group col-lg-12">
                                    <div className="form-label-group">
                                        <label>Ім'я користувача</label>
                                    </div>
                                    <input className={"form-control"}
                                           placeholder="user@example.com"
                                           autoComplete={"on"}
                                           id="username"
                                           {...register("username", {required: true})}
                                           type="email"/>
                                    <ErrorMessage field={"Логін"} type={errors["username"]?.type}/>
                                </div>
                                {/*password*/}
                                <PassInput label="Пароль" name="password" forgotButton={true}
                                           forgotLink="/password-reset"/>
                            </div>
                            <button type="submit" className="btn btn-primary btn-uppercase btn-block" name="submit">
                                Увійти
                            </button>
                            <p className="p-xs mt-2 text-center">
                                <a className="text-dark-60" href="/signup">Новий користувач?&nbsp;&nbsp;
                                    <u className="text-primary">Створити обліковий запис</u>
                                </a>
                            </p>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}

const Login = () => {

    const dispatch = useDispatch();

    let {isAuthenticated} = useContext(AuthContext)
    const navigate = useNavigate();

    useEffect(() => {
        // redirect to home if already logged in
        if (isAuthenticated === true)
            navigate('/');
        else if (typeof isAuthenticated === "boolean")
            dispatch(getCSRF());

    }, [isAuthenticated]);

    return (
        <div className={"hk-wrapper hk-pg-auth"} data-footer={"simple"}>
            <nav className="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
                <BrandNav/>
            </nav>

            <div className={"hk-pg-wrapper"}>
                <AuthBody>
                    <LoginContent/>
                </AuthBody>
                <Footer/>
            </div>

        </div>
    )
}

export default Login

