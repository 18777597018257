import moment from "moment/moment";

const locale = "uk_UA"

export const errorsTypes = {
    OperationErrorTryLater: {
        "uk_UA": "Помилка виконання операції. Повторіть спробу пізніше.",
        "en_US": "Operation error. Try again later."
    },
    RequestTimedOut: {
        "uk_UA": "Час очікування запиту минув.",
        "en_US": "The request timed out."
    },
    NetworkError: {
        "uk_UA": "Помилка мережі.",
        "en_US": "Network Error."
    }
}

export function formatError(errorText) {
    return {dateTime: moment().format("DD-MM-YYYY HH:mm:ss"), message: errorText}
}

export function reportError(error, locale = "uk_UA") {
    return formatError(errorsTypes[error][locale])
}

export function handleError(error) {

    let errorList = []

    switch (error.code) {
        case 'ECONNABORTED':
            errorList.push(reportError("RequestTimedOut", locale))
            break;
        case 'ERR_NETWORK':
            errorList.push(reportError('NetworkError', locale))
            break;
        default: {
            const errorResponse = error.response;
            let errorDetail = errorResponse.statusText;

            if (errorResponse.data.statusText !== undefined) {
                errorDetail += `. ${errorResponse.data.statusText}`
            }

            errorList.push(formatError(`${errorResponse.status} - ${errorDetail}`))

            if (errorResponse.data.errors !== undefined) {
                errorResponse.data.errors.map(el => errorList.push(formatError(el)))
            }
        }
    }
    return errorList
}