import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "axios";
import {handleError} from "../Utils/Errors";

const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;

const initialState = {

    // isAuthenticated: localStorage.getItem('isAuthenticated') === true ? true : false,
    isAuthenticated: localStorage.getItem('isAuthenticated'),
    isAdmin: false,
    isSignUp: false,
    errors: [],
}

export const getCSRF = createAsyncThunk('Auth/getCSRF',
    async () => {
        // console.log('getCSRF')
        const response = await axios.get(`${baseUrl}/csrf/`, {
            withCredentials: true,
        });
        return response.data;
    }
)

export const logout = createAsyncThunk('Auth/logout',
    async () => {
        const response = await axios.get(`${baseUrl}/logout/`, {
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken")
            },
            withCredentials: true,
        });
        return response.data;
    }
)

export const session = createAsyncThunk('Auth/session',
    async () => {
        // console.log('session')
        const response = await axios.get(`${baseUrl}/session/`, {
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken")
            },
            withCredentials: true,
        });
        return response.data;
    }
)

export const login = createAsyncThunk('Auth/login',
    async (signInData) => {

        try {
            const response = await axios.post(`${baseUrl}/login/`,
                signInData,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    },
                    withCredentials: true,
                });
            return {data: response.data, status: response.status};
        } catch (error) {
            const errorsList = handleError(error)
            return {errors: errorsList, status: error.response.status}
        }
    }
)

export const signup = createAsyncThunk('Auth/register',
    async (signUpData) => {

        try {
            const response = await axios.post(`${baseUrl}/signup/`,
                signUpData,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    },
                    withCredentials: true,
                })
                .catch(function (error) {
                    return {data: error.response.data, status: error.status}
                });
            return {data: response.data.errors, status: response.status};
        } catch (error) {
            const errorsList = handleError(error)
            return {errors: errorsList, status: error.response.status}
        }
    }
)

export const Auth = createSlice({
    name: 'Auth2',
    initialState,
    reducers: {
        setErrors: (state, action) => {
            return {...state, errors: [action.payload]}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                if (action.payload.status === 200) {
                    localStorage.setItem('isAuthenticated', true);
                    localStorage.setItem('user-data', JSON.stringify(action.payload.data));
                    return {...state, isAuthenticated: true}
                } else {
                    return {...state, isAuthenticated: false, errors: action.payload.errors}
                }
            })
            .addCase(login.rejected, (state, action) => {
                return {...state, isAuthenticated: false, errors: ['Недійсні облікові дані.']}
            })
            //register
            .addCase(signup.fulfilled, (state, action) => {

                if (action.payload.status === 200) {
                    localStorage.setItem('isAuthenticated', true);
                    return {...state, isAuthenticated: true, isSignUp: true}
                } else {
                    return {...state, isAuthenticated: false, isSignUp: false, errors: action.payload.errors}
                }
            })
            .addCase(signup.rejected, (state, action) => {
                return {...state, isAuthenticated: false, isSignUp: false, errors: ['Помилка при реєстрації користувача. Спробуйте пізніше.']}
            })
            //getCSRF
            .addCase(getCSRF.fulfilled, (state, action) => {
                // const csrfToken = action.payload.get("X-CSRFToken");
                // state.isAuthenticated = false
            })
            // session
            .addCase(session.fulfilled, (state, action) => {
                // state.isAuthenticated = action.payload.isAuthenticated
                localStorage.setItem('isAuthenticated', action.payload.isAuthenticated);
                return {...state, isAuthenticated: action.payload.isAuthenticated, isAdmin: action.payload.isAdmin}
            })
            .addCase(session.rejected, (state, action) => {
                // state.isAuthenticated = false
                // localStorage.setItem('isAuthenticated', false);
                localStorage.clear();
                return {...state, isAuthenticated: false, isAdmin: false}
            })
            //logout
            .addCase(logout.fulfilled, (state, action) => {
                localStorage.clear();
                return {...state, isAuthenticated: false}
            })
    }
})

export const {setErrors} = Auth.actions
export default Auth.reducer