import {useNavigate, useParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import AuthContext from "../AuthContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faHouse} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Cookies from "js-cookie";
import {handleError} from "../../Utils/Errors";
import {setErrors} from "../../Reducers/Errors";
import {useDispatch} from "react-redux";

const locale = "uk_UA"
const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;

const ArticleBody = () => {

    const dispatch = useDispatch()

    const {slug} = useParams();
    const [data, setData] = useState({})

    const getArticle = useCallback(async (slug) => {

        try {
            const response = await axios.get(`${baseUrl}/news/article/${slug}/`,
                {
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    }
                })

            if (response.status === 200)
                setData(response.data.article.fields)

        } catch (error) {
            const errorList = handleError(error)
            dispatch(setErrors(errorList))
        }

    }, [])

    useEffect(() => {
        if (slug !== undefined){
            getArticle(slug)
        }
    }, [getArticle]);

    return (
        <div className="blogapp-detail-wrap">

            <div className="row">
                <div className="order-md-0 order-1 col-lg-3 col-md-5">
                </div>
                <div className="offset-lg-5 offset-md-3 mb-md-0 mb-2 col-lg-4 col-md-4"><h2
                    className="d-flex justify-content-md-end mb-0">Invoice</h2></div>
            </div>

        </div>
    )
}


const ArcticleControlPanel = () => {

    const navigate = useNavigate()

    return (
        <div className="d-xxl-flex d-none align-items-center mb-0">

            <button type="button" className="btn btn-outline-light btn-sm me-2"
                    onClick={() => {
                        navigate(-1)
                    }}>
                <span>
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={faArrowLeft} className="text-primary my-1"/>
                        </span>
                    </span>
                </span>
            </button>

            <button type="button" className="btn btn-outline-light btn-sm me-2"
                    onClick={() => {
                        navigate('/')
                    }}>
                <span>
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={faHouse} className="text-primary my-1"/>
                        </span>
                    </span>
                </span>
            </button>

        </div>
    )
}


const Article = () => {

    const navigate = useNavigate()

    let {isAuthenticated, isAdmin} = useContext(AuthContext)

    useEffect(() => {
        if (isAuthenticated !== true || isAdmin !== true)
            navigate('/')
    }, [isAuthenticated, isAdmin]);

    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                {/*<h1 className="pg-title">Додати нову публікацію</h1>*/}
                <ArcticleControlPanel/>
            </div>
            <div className="hk-pg-body">
                <div className="blogapp-wrap blogapp-sidebar-toggle">
                    <ArticleBody/>
                </div>
            </div>
        </div>
    )
}

export default Article