import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import {handleError, reportError, formatError} from "../Utils/Errors";
import {updateArrayOfObjects} from "../Utils/arrayProcessing";

const locale = "uk_UA"

const initialState = {
    timeStamp: Date.now().toString(),
    data: [],
    errors: [],
}

const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;

export const changeUserActivity = createAsyncThunk('Users/changeUserActivity',
    async (userData) => {

        try {
            const response = await axios.post(`${baseUrl}/users/change-user-activity/`,
                userData,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    },
                    withCredentials: true,
                });

            return {data: response.data, status: response.status};
        }
        catch (error) {
            const errorsList = handleError(error)
            return {errors: errorsList, status: error.response.status}
        }
    }
)

export const updateUserUid = createAsyncThunk('Users/updateUserUid',
    async (userData) => {

        try {
            const response = await axios.post(`${baseUrl}/users/user-uid/${userData.user_name}/`,
                {uid: userData.uid},
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    },
                    withCredentials: true,
                });

            return {data: response.data, status: response.status};
        } catch (error) {
            const errorsList = handleError(error)
            return {errors: errorsList, status: error.response.status}
        }
    }
)

export const Users = createSlice({
    name: 'Users',
    initialState,
    reducers: {
        setData: (state, action) => {
            return {...state, data: action.payload}
        },
        setTimeStamp: (state, action) => {
            return {...state, timeStamp: Date.now().toString()}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeUserActivity.fulfilled, (state, action) => {

                if (action.payload.status === 200) {
                    const currentState = current(state);
                    const newData = updateArrayOfObjects([...currentState.data], 'is_active', action.payload.data.is_active, 'username', action.payload.data.username)
                    return {...state, data: newData}
                }
                else {
                    return {...state, errors: action.payload.errors}
                }
            })
            .addCase(changeUserActivity.rejected, (state, action) => {
                return {...state, errors: reportError("OperationErrorTryLater",locale)}
            })
            .addCase(updateUserUid.fulfilled, (state, action) => {

                if (action.payload.status === 200) {
                    const currentState = current(state);
                    const newData = updateArrayOfObjects([...currentState.data], 'user_has_uid', action.payload.data.user_has_uid, 'username', action.payload.data.username)
                    return {...state, data: newData}
                }
                else {
                    return {...state, errors: action.payload.errors}
                }
            })
            .addCase(updateUserUid.rejected, (state, action) => {
                return {...state, errors: reportError("OperationErrorTryLater",locale)}
            })
    }
})

export const {setData, setTimeStamp} = Users.actions
export default Users.reducer