import initialOrder from "../../../Data/initialOrderState"

import '@fortawesome/fontawesome-svg-core/styles.css'

import {Carousel} from 'react-bootstrap';

import Tab from 'react-bootstrap/Tab';
import Nav from "react-bootstrap/Nav";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../AuthContext";
import axios from "axios";
import Cookies from "js-cookie";

import {useParams} from "react-router-dom";
import AdditionalPanel from "./AdditionalPanel";

const StageOuter = ({images}) => {

    return (
        <div className="product-detail-slider d-flex justify-content-center">
            <Carousel className="owl-carousel owl-primary owl-loaded owl-drag" variant="dark" wrap={false}
                      style={{width: "60%", height: "60%"}}>
                {
                    images.map(el =>
                        <Carousel.Item key={el.fileName}>
                            <img className="d-block w-100"
                                 style={{width: "460px"}}
                                 src={`data:image/jpeg;base64,${el.image}`}/>
                        </Carousel.Item>
                    )
                }
            </Carousel>
        </div>
    )
}

const OrderMainIfo = ({data}) => {
    return (
        <div className="content-aside">
            <div className="card card-border">
                <div className="card-body">
                    <h6 className="mb-4">Деталі замовлення</h6>
                    <ul className="list-unstyled">
                        <li className="mb-3">
                            <div className="fs-7">Номер</div>
                            <div className="text-dark fw-medium">{data.number}</div>
                        </li>
                        <li className="mb-3">
                            <div className="fs-7">Дата</div>
                            <div className="text-dark fw-medium">{data.date}</div>
                        </li>
                        <li className="mb-3">
                            <div className="fs-7">Дата готовності</div>
                            <div className="text-dark fw-medium">{data.delivery_date}</div>
                        </li>
                        <li className="mb-3">
                            <div className="fs-7">Дата доставки</div>
                            <div className="text-dark fw-medium">{data.execution_date}</div>
                        </li>
                        <li className="mb-3">
                            <div className="fs-7">Сума</div>
                            <div className="text-dark fw-medium d-flex align-items-center">
                                {data.amount.toFixed(2)}
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="fs-7">Менеджер</div>
                            <div className="text-dark fw-medium d-flex align-items-center">
                                {data.manager}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

const OrderGoodsRow = ({row}) => {
    return (
        <tr className="odd">
            <td className="sorting_1">
                {row.name}
            </td>
            {/*<td>Decco 62, Стандарт, Білий, Кон: 19, Вага(кг): 430</td>*/}
            <td>
                <div>{row.profile_system},&nbsp;&nbsp;{row.profile_type},&nbsp;&nbsp;{row.color},</div>
                <div>Кон:&nbsp;{row.structures},&nbsp;&nbsp;Вага(кг):&nbsp;{row.weight}</div>
            </td>
            <td>{row.quantity}&nbsp;&nbsp;{row.qtype}</td>
            <td>{row.amount.toFixed(2)}</td>
        </tr>
    )
}

const OrderGoods = ({goods}) => {
    return (
        <div className="row">
            <table id="datable_goods" className="table nowrap w-100 mb-5 dataTable no-footer" role="grid"
                   aria-describedby="datable_goods_info">
                <thead>
                <tr role="row">
                    <th className="sorting_disabled" tabIndex="0" aria-controls="datable_1" rowSpan="1" colSpan="1"
                        aria-sort="ascending"
                        style={{width: "151.438px"}}>
                        <h6>Товар / Продукція</h6>
                    </th>
                    <th className="sorting_disabled" tabIndex="0" aria-controls="datable_1" rowSpan="1" colSpan="1"
                        aria-sort="ascending"
                        style={{width: "151.438px"}}>
                        <h6>Характеристика</h6>
                    </th>
                    <th className="sorting_disabled" tabIndex="0" aria-controls="datable_1" rowSpan="1" colSpan="1"
                        aria-sort="ascending"
                        style={{width: "151.438px"}}>
                        <h6>Кількість</h6>
                    </th>
                    <th className="sorting_disabled" tabIndex="0" aria-controls="datable_1" rowSpan="1" colSpan="1"
                        aria-sort="ascending"
                        style={{width: "151.438px"}}>
                        <h6>Сума</h6>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    goods.map(el => <OrderGoodsRow key={el.number} row={el}/>)
                }
                </tbody>
            </table>
        </div>
    )
}

const OrderServicesRow = ({row}) => {
    return (
        <tr className="odd">
            <td className="sorting_1">
                {row.name}
            </td>
            <td>{row.quantity}&nbsp;&nbsp;{row.qtype}</td>
            <td>{row.amount}</td>
        </tr>
    )
}

const OrderServices = ({services}) => {
    return (
        <div className="row">
            <table id="datable_goods" className="table nowrap w-100 mb-5 dataTable no-footer" role="grid"
                   aria-describedby="datable_goods_info">
                <thead>
                <tr role="row">
                    <th className="sorting_disabled" tabIndex="0" aria-controls="datable_1" rowSpan="1" colSpan="1"
                        aria-sort="ascending"
                        style={{width: "151.438px"}}>
                        <h6>Послуга</h6>
                    </th>
                    <th className="sorting_disabled" tabIndex="0" aria-controls="datable_1" rowSpan="1" colSpan="1"
                        aria-sort="ascending"
                        style={{width: "151.438px"}}>
                        <h6>Кількість</h6>
                    </th>
                    <th className="sorting_disabled" tabIndex="0" aria-controls="datable_1" rowSpan="1" colSpan="1"
                        aria-sort="ascending"
                        style={{width: "151.438px"}}>
                        <h6>Сума</h6>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    services.map(row => <OrderServicesRow key={row.number} row={row}/>)
                }
                </tbody>
            </table>
        </div>
    )
}

const OrderAdditionally = ({data}) => {
    return (
        <div className="content-aside">
            <div className="card border-0 shadow-none">
                <div className="card-body">
                    <ul className="list-unstyled">
                        <li className="mb-3">
                            <div className="fs-7">Адреса доставки</div>
                            <div className="text-dark fw-medium">{data.delivery_address}</div>
                        </li>
                        <li className="mb-3">
                            <div className="fs-7">Контактна особа</div>
                            <div className="text-dark fw-medium">{data.additional_address}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

const OrderPreview = () => {

    const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;

    let {isAuthenticated} = useContext(AuthContext)
    let {uid} = useParams();

    const [data, setData] = useState(initialOrder);

    useEffect(() => {
        if (isAuthenticated === true) {
            axios.get(`${baseUrl}/orders/order-details/${uid}/`,
                {
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    }
                }).then(function (response) {
                if (response.status === 200) {
                    setData(response.data.data);
                    // console.log(`axios.get - 200 - ${response.data.data}`);
                }
            }).catch(function (error) {
                // console.log("axios.get - ERROR");
                // console.log(error);
            })
        }
    }, []);

    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">Деталі замовлення</h1>
            </div>
            <div className="hk-pg-body">
                <div className="container md-3">
                    <div className="row">
                        <div className="col-xxl-8 col-lg-7">
                            <div className="media">
                                <div className="media-body">
                                    <h3 className="hd-bold mb-0">№&nbsp;{data.doc.number}</h3>
                                    <span>{`від ${data.doc.date}`}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-lg-5 mt-lg-0 mt-3">
                            <AdditionalPanel uid={uid}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-xxl-8 col-lg-7">
                            {/*Pictures*/}
                            <StageOuter images={data.images}/>
                            <div className="separator"></div>

                            <Tab.Container id="left-tabs" defaultActiveKey="tab_goods">
                                <Nav className="nav nav-light nav-pills nav-pills-rounded justify-content-center"
                                     as="ul">
                                    <Nav.Item className="nav-item" as="li">
                                        <Nav.Link className="nav-link" data-bs-toggle="pill" eventKey="tab_goods">
                                            <span className="nav-link-text">Товари</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item" as="li">
                                        <Nav.Link className="nav-link" data-bs-toggle="pill" eventKey="tab_services">
                                            <span className="nav-link-text">Послуги</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item" as="li">
                                        <Nav.Link className="nav-link" data-bs-toggle="pill"
                                                  eventKey="tab_additionally">
                                            <span className="nav-link-text">Додатково</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <div className="tab-content py-3">
                                        <Tab.Pane eventKey="tab_goods"><OrderGoods goods={data.goods}/></Tab.Pane>
                                        <Tab.Pane eventKey="tab_services"><OrderServices
                                            services={data.services}/></Tab.Pane>
                                        <Tab.Pane eventKey="tab_additionally"><OrderAdditionally
                                            data={data.doc}/></Tab.Pane>
                                    </div>
                                </Tab.Content>
                            </Tab.Container>

                        </div>
                        <div className="col-xxl-4 col-lg-5">
                            <OrderMainIfo data={data.doc}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderPreview