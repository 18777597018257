import {useState} from "react";
import {faCalendarDays, faMapLocationDot, faPrint} from "@fortawesome/free-solid-svg-icons";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import DateWindow from "./DateWindow";
import AddressWindow from "./AddressWindow";
import PrintWindow from "./PrintWindow";

const AdditionalPanel = ({uid}) => {

    const [showDateWindow, setShowDateWindow] = useState(false)
    const [showAddressWindow, setShowAddressWindow] = useState(false)
    const [showPrintWindow, setShowPrintWindow] = useState(false)

    return (
        <>
            <div className="d-flex mt-3">
                <AdditionalPanelButton icon={faPrint} title={"Друкувати"} setShowWindow={setShowPrintWindow}
                                       className={"btn btn-sm btn-light btn-block"}/>
                <PrintWindow show={showPrintWindow} showHandler={setShowPrintWindow} uid={uid}/>
                {/*<button className="btn btn-sm btn-light btn-block">*/}
                {/*    <span><span className="icon"><span className="feather-icon">*/}
                {/*        <FontAwesomeIcon icon={faPrint} className="feather"/>*/}
                {/*    </span></span><span>Друкувати</span></span>*/}
                {/*</button>*/}
                <AdditionalPanelButton icon={faEnvelope} title={"Написати"}
                                       className={"btn btn-sm btn-light btn-block ms-2 mt-0"}/>
                {/*<button className="btn btn-sm btn-light btn-block ms-2 mt-0">*/}
                {/*    <span><span className="icon"><span className="feather-icon">*/}
                {/*        <FontAwesomeIcon icon={faEnvelope} className="feather"/>*/}
                {/*    </span></span><span>Написати</span></span>*/}
                {/*</button>*/}
            </div>

            <div className="d-flex mt-3">
                <AdditionalPanelButton icon={faCalendarDays} title={"Дата"} setShowWindow={setShowDateWindow}
                                       className={"btn btn-sm btn-light btn-block"}/>
                <DateWindow show={showDateWindow} showHandler={setShowDateWindow}/>
                {/*<button className="btn btn-sm btn-light btn-block">*/}
                {/*                    <span><span className="icon"><span className="feather-icon">*/}
                {/*                        <FontAwesomeIcon icon={faCalendarDays} className="feather"/>*/}
                {/*                    </span></span><span>Дата</span></span>*/}
                {/*</button>*/}
                <AdditionalPanelButton icon={faMapLocationDot} title={"Адреса"} setShowWindow={setShowAddressWindow}
                                       className={"btn btn-sm btn-light btn-block ms-2 mt-0"}/>
                <AddressWindow show={showAddressWindow} showHandler={setShowAddressWindow} uid={uid}/>
                {/*<button className="btn btn-sm btn-light btn-block ms-2 mt-0">*/}
                {/*        <span><span className="icon"><span className="feather-icon">*/}
                {/*            <FontAwesomeIcon icon={faMapLocationDot} className="feather"/>*/}
                {/*        </span></span><span>Адреса</span></span>*/}
                {/*</button>*/}
            </div>
        </>
    )
}

const AdditionalPanelButton = ({icon, title, className, setShowWindow}) => {
    return (
        <button className={className} onClick={() => {
            setShowWindow(true)
        }}>
            <span><span className="icon"><span className="feather-icon">
                <FontAwesomeIcon icon={icon} className="feather"/>
            </span></span><span>{title}</span></span>
        </button>
    )
}

export default AdditionalPanel