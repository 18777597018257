import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    currentItems: [],
}

export const Pagination = createSlice({
    name: 'Pagination',
    initialState:initialState,
    reducers: {
        setCurrentItems: (state, action) => {
            return {...state, currentItems: action.payload}
        }
    }
})

export const {
    setCurrentItems
} = Pagination.actions
export default Pagination.reducer