import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";

import {Table} from "react-bootstrap";
// import ReactPaginate from "react-paginate";

import {setData} from "../../../Reducers/Orders";
// import {setPeriod,  setMoment} from "../../../Reducers/DateRangePicker";
import {setErrors} from "../../../Reducers/Errors"

import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import AuthContext from "../../AuthContext";
import axios from "axios";
import Cookies from "js-cookie";
import {handleError} from "../../../Utils/Errors";
import {emptyOrValue} from "../../../Utils/utils.js"

import {Range} from "../../DateRangePicker/DateRangePicker";
import Pagination from "../../Pagination/Pagination";
import moment from "moment";

const ReportTableRow = ({rowData}) => {
    return (
        <tr className="odd">
            <td className="sorting_1">
                <div className="media align-items-center">
                    <div className="media-body">
                        <div className="text-high-em">
                            <a href={`/orders/order-details/${rowData.uid}`}
                               className="table-link-text link-high-em">{rowData.number}</a>
                        </div>
                    </div>
                </div>
                <div className="fs-7">{rowData.date}</div>
            </td>
            <td>{rowData.delivery_address}</td>
            <td>{rowData.delivery_date}</td>

            <td>
                <div className="text-dark">{rowData.manager.last_name}</div>
                <div className="text-dark">{rowData.manager.first_name}</div>
            </td>

            <td>{emptyOrValue(rowData.order_amount)}</td>
            <td className="text-danger">{emptyOrValue(rowData.debt_amount)}</td>

        </tr>
    )
}

const ReportTable = () => {

    const data = useSelector(state => state.Pagination.currentItems)
    // console.log(`ReportTable - ${moment().format("DD.MM.YYYY HH:mm:ss sss")}`)
    return (
        <Table responsive>
            <thead>
            <tr role="row">
                <th className="sorting_disabled mnw-150p" tabIndex="0" rowSpan="1" colSpan="1">
                    Замовлення #
                </th>
                <th className="sorting_disabled w-25 mnw-425p" tabIndex="0" rowSpan="1" colSpan="1">
                    Адреса доставки
                </th>
                <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1">
                    Дата доставки
                </th>
                <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1">
                    Менеджер
                </th>
                <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1">
                    Сума
                </th>
                <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1">
                    Борг
                </th>
            </tr>
            </thead>
            <tbody>
            {
                data.map(el => <ReportTableRow key={el.row_id} rowData={el}/>)
            }
            </tbody>
        </Table>
    )
}

const ReportBody = () => {

    const rData = useSelector(state => state.Orders.data);
    // console.log(`ReportBody - ${moment().format("DD.MM.YYYY HH:mm:ss sss")}`)

    return (
        <div className="card card-border mb-0 h-100">
            <div className="card-body">
                <div className="contact-list-view">
                    <div id="datable_1_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        {/*Head*/}
                        <div className="row">
                            <div className="col-10 mb-3">
                                <div className="contact-toolbar-left">
                                    <div className="input-group w-230p mx-3">
                                        <span className="input-affix-wrapper">
                                            <span className="input-prefix">
                                                <span className="feather-icon">
                                                    <FontAwesomeIcon icon={faCalendar} className="feather me-2"/>
                                                </span>
                                            </span>
                                            <Range/>
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <div className="col-2 mb-3">
                                <div className="contact-toolbar-right">
                                    <div id="datable_1_filter" className="dataTables_filter">
                                        <label>
                                            <input type="search" className="form-control form-control-sm"
                                                   placeholder="Пошук" aria-controls="datable_1"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Head*/}
                        {/*Table*/}
                        <div className="row">
                            <div className="col-sm-12 table-responsive">
                                <ReportTable/>
                            </div>
                        </div>
                        {/*Table*/}
                        {/*Pagination*/}
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                                    <Pagination itemsPerPage={10} data={rData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="d-flex justify-content-start">
                            <h4>Сума заборгованості: <span className=" ms-3 text-danger">{emptyOrValue(rData.reduce((n,{debt_amount}) => n + debt_amount, 0 ))}</span> </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const DebtAnalysis = () => {

    const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;
    const dispatch = useDispatch()

    let {isAuthenticated} = useContext(AuthContext)

    const dateRange = useSelector(state => state.DateRangePicker);

    useEffect(() => {

        if (isAuthenticated === true) {
            // console.log(`DebtAnalysis - ${moment().format("DD.MM.YYYY HH:mm:ss sss")}`)
            axios.get(`${baseUrl}/reports/debt_analysis/${dateRange.period.startDate}/${dateRange.period.endDate}/`,
                {
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    }
                }).then(function (response) {

                if (response.status === 200) {

                    const responseData = response.data;
                    switch (responseData.status) {
                        case "SUCCESS":
                            dispatch(setData(responseData.data))
                            break;
                        default:
                            dispatch(setErrors([`${responseData.status} - Невідома помилка. Повторіть спробу пізніше.`]))
                    }
                }

            }).catch(function (error) {
                const errorList = handleError(error)
                dispatch(setErrors(errorList))
            })
        }

    }, [dateRange.timeStamp]);

    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">Аналіз заборгованості</h1>
            </div>
            <div className="hk-pg-body">
                <div className="row">
                    <div className="col-md-12 mb-md-4 mb-3">
                        <ReportBody/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DebtAnalysis